@font-face {
  font-family: "Assistant-Regular";
  src: url("./assets/fonts/Assistant-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Assistant-Bold";
  src: url("./assets/fonts/Assistant-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Assistant-ExtraBold";
  src: url("./assets/fonts/Assistant-ExtraBold.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

body {
  color: #333;
  font-family: "Assistant-Regular";
  font-size: 15px;
  line-height: 1.4em;
}

h1 {
  font-family: "Assistant-ExtraBold";
  font-size: 48px;
  line-height: 48px;
  margin: 0 0 15px;
}
@media (min-width: 700px) {
  h1 {
    font-size: 72px;
    line-height: 72px;
  }
}

h2 {
  font-family: "Assistant-ExtraBold";
  font-size: 36px;
  line-height: 36px;
  margin: 0 0 15px;
}
@media (min-width: 700px) {
  h2 {
    font-family: "Assistant-ExtraBold";
    font-size: 42px;
    line-height: 42px;
  }
}

h3 {
  font-family: "Assistant-ExtraBold";
  font-size: 15px;
  margin-bottom: 10px;
}

p {
  font-size: 15px;
  margin: 0 0 20px;
}
p:last-of-type {
  margin-bottom: 0;
}

strong {
  font-family: "Assistant-Bold";
}

ul {
  margin: 20px 0 20px 17px;
  padding: 0;
}
ul li {
  margin-bottom: 10px;
}
ul li:last-of-type {
  margin-bottom: 0;
}
